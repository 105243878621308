import $ from "jquery";

document.addEventListener("DOMContentLoaded", function() {
    for (const dropdown of document.querySelectorAll(".custom-select-wrapper")) {
        dropdown.addEventListener('click', function() {
            this.querySelector('.custom-select').classList.toggle('open');
        })
    }
    for (const option of document.querySelectorAll(".custom-option")) {
        option.addEventListener('click', function() {
            selectOption(this);
        })
    }
    window.addEventListener('click', function(e) {
        for (const select of document.querySelectorAll('.custom-select')) {
            if (!select.contains(e.target)) {
                select.classList.remove('open');
            }
        }
    });

    $('#registerDemandForm').on('submit', function(event){
        let form = this;
        let $form = $(this);
        let url = $form.attr('action');
        let data = $form.serialize();

        $('.invalid-feedback').remove();
        $('.is-invalid').removeClass('is-invalid');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type:'POST',
            url: url,
            data: data,
            success:function(data){
                console.log(data);

                if (data.status == 1) {
                    form.reset();
                    notify("success", data.data);
                } else {
                    let obj = data.data;
                    let message = '';
                    if (typeof obj === 'object') {
                        Object.keys(obj).map(function(key) {
                            message += obj[key] + '\n';
                        });
                    }
                    else {
                        message += obj + '\n';
                    }

                    notify("error", message);
                }
            },
            error: function (xhr, status, errorThrown) {
             console.log(xhr.status);
             console.log(status);
             console.log(errorThrown);
            }
        });

        return false;
    });


});

function selectOption(element) {
    if (!element.classList.contains('selected')) {
        let selected = element.parentNode.querySelector('.custom-option.selected');
        if (selected) {
            selected.classList.remove('selected');
        }
        element.classList.add('selected');
        element.closest('.custom-select').querySelector('.custom-select__trigger span').textContent = element.textContent;

        // set hidden input value
        let input = element.getAttribute('data-input');
        let value = element.getAttribute('data-value');
        document.getElementById(input).value = value;
    }
}

function setSelectedOption(options){
    event.preventDefault();
    openModal('modal');
    document.querySelector('[data-value="' + options.typ + '"]').click();
}

function setSelectedBroker(pattern) {
    document.querySelector('[data-value="' + pattern + '"]').selected = true;
}
